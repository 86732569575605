import React, { useState, useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-okaidia.css";

const HeroSection = () => {
  const [code, setCode] = useState("// 在这里输入你的代码\nconsole.log('Hello World！');");
  const [output, setOutput] = useState(""); // 用于存储代码运行结果

  // 语法高亮
  useEffect(() => {
    Prism.highlightAll();
  }, [code]);

  const runCode = () => {
    try {
      // 创建虚拟环境捕获 `console.log`
      const consoleLog = [];
      const customConsole = {
        log: (...args) => {
          consoleLog.push(args.join(" "));
        },
      };

      // 使用 eval 执行代码，并绑定自定义 console
      const wrappedCode = `(function() { const console = arguments[0]; ${code} })`;
      // eslint-disable-next-line no-eval
      eval(wrappedCode)(customConsole);

      // 更新输出结果
      setOutput(consoleLog.join("\n"));
    } catch (err) {
      setOutput("代码错误：" + err.message);
    }
  };

  return (
    <section className="hero-section bg-secondary-bg text-primary-text p-6">
      <h2 className="text-highlight text-3xl font-bold mb-4">开始你的编程之旅！</h2>
      <textarea
        className="w-full h-40 bg-primary-bg text-highlight p-4 rounded resize-none"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder="// 在这里输入代码"
      />
      <pre className="mt-4 rounded bg-primary-bg p-4 overflow-auto">
        <code className="language-javascript">{code}</code>
      </pre>
      <button
        className="mt-4 bg-highlight text-primary-bg p-2 rounded hover:bg-opacity-90"
        onClick={runCode}
      >
        运行代码
      </button>
      <div className="mt-6 p-4 bg-primary-bg rounded text-highlight">
        <h3 className="text-xl font-bold mb-2">运行结果:</h3>
        <pre>{output || "暂无输出"}</pre>
      </div>
    </section>
  );
};

export default HeroSection;
