import React from "react";

const Header = () => {
    return (
        <header className="bg-primary-bg text-primary-text p-4 shadow-md flex justify-between items-center">
            <h1 className="text-highlight text-xl font-bold">
                <a href="/" className="hover:text-highlight">
                    编程代码学习
                </a>
            </h1>
            <nav>
                <ul className="flex space-x-4">
                    <li>
                        <a href="/" className="hover:text-highlight">
                            首页
                        </a>
                    </li>
                    <li>
                        <a href="/articles" className="hover:text-highlight">
                            我的文章
                        </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="/about" className="hover:text-highlight">*/}
                    {/*        关于我们*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                </ul>
            </nav>
        </header>
    );
};

export default Header;
