import React from "react";

const Footer = () => {
  return (
    <footer className="sticky bottom-0 bg-secondary-bg text-secondary-text p-4 text-center">
      <p>© 2024 编程代码学习. 版权所有。</p>
      <p>
        <a
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="noopener noreferrer"
          className="text-highlight hover:underline"
        >
          豫ICP备2020030901号-1
        </a>
      </p>
    </footer>
  );
};

export default Footer;
