import React from "react";

const Articles = () => {
    const articles = [
        {id: 1, title: "如何开始学习编程：新手完全指南", date: "2024-11-20"},
        {id: 2, title: "JavaScript 入门：从零基础到构建简单应用", date: "2024-11-22"},
        {id: 3, title: "Python 数据分析入门：从基础到实战", date: "2024-11-25"},
    ];

    return (
        <section className="bg-secondary-bg text-primary-text p-6 flex flex-col items-center">
            <h2 className="text-highlight text-3xl font-bold mb-4">我的文章</h2>
            <ul className="w-full max-w-2xl">
                {articles.map((article) => (
                    <li key={article.id} className="mb-4">
                        <a
                            href={`/article/${article.id}`}
                            className="text-highlight text-xl hover:underline"
                        >
                            {article.title}
                        </a>
                        <p className="text-secondary-text text-sm">{article.date}</p>
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default Articles;
