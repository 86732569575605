import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Articles from "./components/Articles";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import ArticleDetail from "./components/ArticleDetail";

function App() {
    return (
        <Router>
            <div className="App flex flex-col min-h-screen">
                <Header/>
                <main className="flex-grow">
                    <Routes>
                        <Route path="/" element={<HeroSection/>}/>
                        <Route path="/articles" element={<Articles/>}/>
                        <Route path="/article/:id" element={<ArticleDetail/>}/>
                        <Route path="/about" element={<div>关于我们</div>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </main>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
