import React from "react";

const NotFound = () => {
  return (
    <div className="bg-secondary-bg text-primary-text p-6 text-center h-full flex flex-col justify-center items-center">
      <h1 className="text-highlight text-5xl font-bold mb-4">404</h1>
      <p className="text-xl mb-4">抱歉，您访问的页面不存在。</p>
      <a
        href="/"
        className="mt-4 bg-highlight text-primary-bg p-2 rounded hover:bg-opacity-90"
      >
        返回首页
      </a>
    </div>
  );
};

export default NotFound;
