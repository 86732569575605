import React from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // 支持 GitHub 风格的 Markdown（表格、任务列表等）
import remarkMath from "remark-math"; // 支持数学公式
import rehypeKatex from "rehype-katex"; // 渲染数学公式
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"; // 代码高亮
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism"; // 代码高亮主题
import articles from "../data/articles.json";

const ArticleDetail = () => {
  const { id } = useParams(); // 从路由参数中获取文章 ID
  const article = articles.find((a) => a.id === parseInt(id, 10)); // 查找对应文章

  // 自定义代码块渲染器
  const renderers = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={oneDark}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  if (!article) {
    return (
      <div className="bg-secondary-bg text-primary-text p-6 text-center flex flex-col justify-center items-center">
        <h1 className="text-highlight text-3xl font-bold mb-4">文章未找到！</h1>
      </div>
    );
  }

  return (
    <article className="bg-secondary-bg text-primary-text p-6 flex flex-col items-center">
      <div className="w-full max-w-2xl">
        <h1 className="text-highlight text-4xl font-bold mb-4">{article.title}</h1>
        <p className="text-secondary-text text-sm mb-6">{article.date}</p>
        <ReactMarkdown
          className="markdown-body"
          children={article.content}
          remarkPlugins={[remarkGfm, remarkMath]}
          rehypePlugins={[rehypeKatex]}
          components={renderers}
        />
      </div>
    </article>
  );
};

export default ArticleDetail;
